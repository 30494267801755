import './bootstrap';
import '../css/app.css';
import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import {Ziggy} from "@/ziggy.js";
import {VueReCaptcha} from 'vue-recaptcha-v3'
import {createPinia} from 'pinia'
import UserOnlineHandler from "@/plugins/UserOnlineHandler.js";
import MessageHandler from "@/plugins/MessageHandler.js";
import * as Sentry from "@sentry/vue";
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isToday from 'dayjs/plugin/isToday'
import 'dayjs/locale/ru'
import VueMask from '@devindex/vue-mask';
import {getActiveLanguage, i18nVue} from "laravel-vue-i18n";

dayjs.extend(customParseFormat)
dayjs.extend(isToday)

const pinia = createPinia()
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'AnyKod';

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(VueMask)
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(pinia)
            .use(MessageHandler, props.initialPage.props.auth)
            .use(VueReCaptcha, {
                siteKey: import.meta.env.VITE_NOCAPTCHA_SITEKEY ?? '',
                loaderOptions: {
                    autoHideBadge: true
                }
            })
            .use(i18nVue, {
                resolve: async lang => {
                    const languages = import.meta.glob('../../lang/*.json');
                    return await languages[`../../lang/${lang}.json`]();
                }
            })
            .use(UserOnlineHandler, props.initialPage.props.auth)
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
            environment: import.meta.env.APP_ENV,
        });
        // Sentry configuration
        app.mixin(Sentry.createTracingMixins({trackComponents: true}));
        Sentry.attachErrorHandler(app, {logErrors: true});
        dayjs.locale(getActiveLanguage())
        return app.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
})
